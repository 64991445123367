import React from "react";

const FirstSlide = (
    <>
        <span className="small-title">CROSS PLATFORM APPLICATIONS</span>
        <strong id="large-service-title">MOBILE NATIVE APPLICATIONS</strong>
        <span className="subTitle">From idea to the appstore</span>
    </>
);

export default FirstSlide;