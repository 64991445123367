import React, {Component} from "react";
import PropTypes from 'prop-types';
import 'animation.gsap'
import 'debug.addIndicators'
import ScrollMagic from 'scrollmagic';
import Utils from "./../../utils/utils";
import {SCROLL_DIRECTIONS} from "../../utils/constants";
import Arrow from "../arrow/index";
import './style.scss';
import './mobile.scss';
import smoothscroll from 'smoothscroll-polyfill';


const TweenMax = require('TweenMax');


class Subgrid extends Component {


    constructor(props) {
        super(props);
        this.scene = [];
        this.controller = typeof window !== 'undefined' && new ScrollMagic.Controller();

        this.state = {
            scrollDirection: SCROLL_DIRECTIONS.PAUSED,
            activeSlide: 0,
            time: Date.now(),
            isControlScroll: false
        }
    }



    gotoNextSlide = () => {
        const {sectionsTiming} = this.props;

        window.scroll({
            top: Utils.calcSectionOffset(sectionsTiming, Math.min(this.state.activeSlide + 1, sectionsTiming.length)),
            left: 0,
            behavior: sectionsTiming[this.state.activeSlide].animate ?  'smooth'  : 'auto'

        });
    }
    gotoPrevSlide = () => {
        const {sectionsTiming} = this.props;
        this.setState({...this.state, activeSlide: Math.max(0, this.state.activeSlide - 1)});

        const prevSlideIndex = this.state.activeSlide + 1;
        window.scroll({
            top: Utils.calcSectionOffset(sectionsTiming, prevSlideIndex) - 1,
            left: 0,
            behavior: sectionsTiming[prevSlideIndex].animate ?  'smooth'  : 'auto'
        });
    }
    //we want to listen to scroll event but we don't want it to fire too many times - hence we filter it
    throttle = (fn, wait, props) => {
        if ((this.state.time + wait - Date.now()) < 0) {
            fn(props);
            this.setState({...this.state, time: Date.now()});
        }
    }
    handleScrollProgress = event => {
        const {sectionsTiming} = this.props;
        const index = this.state.activeSlide;

        //set scroll position for css animations
        if (this.state.scrollDirection !== event.scrollDirection && (event.progress < 1 && event.progress > 0))
            this.setState({...this.state, scrollDirection: event.scrollDirection});

        //We swap slide which don't have scroll animation in them
        if (this.state.isControlScroll && sectionsTiming[index].animate === false) {
            if (this.state.scrollDirection === SCROLL_DIRECTIONS.FORWARD) {
                this.gotoNextSlide();
            } else if (this.state.scrollDirection === SCROLL_DIRECTIONS.REVERSE) {
                this.gotoPrevSlide();
            }

        }

        this.setState({...this.state, isControlScroll: (sectionsTiming[index].animate === false)});

    };

    handelEnterSection = (index) => {
        const {sectionsTiming, pageTitle} = this.props;

        //we delay the isControlScroll flag in order that scroll event fires will not skip slides.
        //this happens when we move from slide with animtion to a non-animation slide

        Utils.gTagEvent({
            category: pageTitle,
            label: `Section ${index + 1} / ${this.props.textSlides[index].className}`,
            action: 'Section View',
            nonInteraction: true
        });

        setTimeout(() => this.setState({
            ...this.state,
            isControlScroll: (sectionsTiming[index].animate === false)
        }), 1000);

        if (this.state.activeSlide !== index) {
            this.setState({...this.state, activeSlide: index});
        }

        const {onSectionChange} = this.props;
        if (onSectionChange) {
            onSectionChange(index)
        }

    }

    setSectionScene = (section, index) => {
        const {sectionsTiming} = this.props;
        const that = this;


        this.scene[index] = new ScrollMagic.Scene({
            offset: Utils.calcSectionOffset(sectionsTiming, index),
            duration: section.duration
        })
            .setClassToggle('.container', section.name)
            // .addIndicators({name: (index + 1)  + ' - ' + section.name + ' IN'}) // add indicators
            .on("enter", () => that.handelEnterSection(index))
            .on('progress', (e) => {
                this.throttle(this.handleScrollProgress, 1500, e)
            })
            .addTo(this.controller);

    };

    componentWillUnmount() {
        this.scene.forEach(function (el) {
            el.destroy(true);
            el = null;
        });

        this.controller = null;
    }

    componentDidMount() {
        //fix edge problem with smooth scrolling
        smoothscroll.polyfill();

        const {sectionsTiming} = this.props;

        // build scene - ALL sections Timing In
        sectionsTiming.forEach(this.setSectionScene);

        // build scene - background section-1 up
        this.scene.push(new ScrollMagic.Scene({offset: 0, duration: Utils.calcDuration(0.5)})
            .setTween('#grid-squares', {y: '-100%', ease: TweenMax.Linear.easeNone})
            // .addIndicators({name: '1 - background up'}) // add indicators
            .addTo(this.controller));
    }

    render() {
        const {textSlides, imagesSlides, hasArrow} = this.props;
        const {scrollDirection} = this.state;

        return (
            <>
            <div className="subgrid" data-direction={scrollDirection}>
                <ul className="text-area">
                    {
                        textSlides.map((item, index) => (
                            <li key={index} className={`${item.className || ''}`}>{item.content}</li>
                        ))
                    }
                </ul>
                <ul className="image-area">
                    {
                        imagesSlides.map((item, index) => (
                            <li className={`${item.className || ''}`} key={index}>{item.content}</li>
                        ))
                    }
                </ul>
            </div>
            {hasArrow && <Arrow onClick={this.gotoNextSlide}/>}
            </>
        )
    }
}


Subgrid.propTypes = {
    imagesSlides: PropTypes.array,
    textSlides: PropTypes.array,
    sectionsTiming: PropTypes.array,
    footerOffset: PropTypes.number,
    onSectionChange: PropTypes.func,
    hasArrow: PropTypes.bool,
};

Subgrid.defaultProps = {
    imagesSlides: [],
    textSlides: [],
    sectionsTiming: [],
    footerOffset: 0,
    hasArrow: true,
};

export default Subgrid;