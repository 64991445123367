import React, {Component} from "react";
import 'animation.gsap'
import 'debug.addIndicators'
import ScrollMagic from 'scrollmagic';
import Layout from "../components/layout";
import Subgrid from "../components/subgrid";
import Utils from "../utils/utils";
import {IS_MOBILE} from "../utils/constants";
import mask from "../resources/images/mobile-native-applications/mask.png";
import pixel from "../resources/images/mobile-native-applications/1pixel.jpg";
import ImageAscii, {asciiScrollAnimation} from "../components/image-ascii";
import TweenMax from "gsap/TweenMax";
import FirstSlide from "../components/mobile-native-applications-slides/first-slide";
import PlatformsSlide from "../components/mobile-native-applications-slides/platforms-slide";
import ScheduleSlide from "../components/mobile-native-applications-slides/schedule-slide";
import {graphql} from 'gatsby'

const images = [...Array(83)].map((x, i) => "/mobile-app-sprites/frames/Scene." + (i + 31) + ".jpg");
const imagesMasks = [...Array(83)].map((x, i) => "/mobile-app-sprites/frames/masks/Scene." + (i + 31) + ".png");

// This is for efficiency, to query the height only once every 10 requests
let test = 0, lastHeight = 0;
const getImageHeight = () => {

    if (test !== 0) {
        test += 1;
        if (test === 10) test = 0;
        return lastHeight;
    }

    const image = document.querySelector('.sprites > picture > img');
    const height = image ? image.getBoundingClientRect().height : 0;
    lastHeight = height;
    if (height > 0) test = 1;

    return height;
};

const updatePhonesAnim = (cur, tween, wrapper, sprites) => {
    const height = getImageHeight();

    wrapper.style.height = `${height}px`;
    wrapper.style.transform = !IS_MOBILE ? `translateX(${0 - 45 * ((tween && tween.progress()) || 0)}%)` : 'translateX(0)';
    sprites.style.transform = `translateY(-${cur * height}px)`;
};

const sectionsTiming = [
    {
        name: 'section-1',
        duration: IS_MOBILE ? Utils.calcDuration(1) : Utils.calcDuration(.5),
        animate: !IS_MOBILE

    },
    {
        name: 'section-2',
        duration: Utils.calcDuration(1),
        animate: false

    },
    {
        name: 'section-3',
        duration: Utils.calcDuration(1.25),
        animate: true
    },
];

class MobileNativeApplicationsPage extends Component {
    constructor(props) {
        super(props);
        this.scene = [];
        this.state = {
            isMobile: false
        }
        this.pixelImgTag = () => {
        };
    }

    componentDidMount() {
        if (IS_MOBILE) {
            this.setState({isMobile: true})
            this.pixelImgTag = (k) => <img src={pixel} alt=""/>
        }

        if (typeof window === 'undefined') return;

        // init controller
        const controller = new ScrollMagic.Controller();

        if (!IS_MOBILE) {

            // TweenMax can tween any property of any object. We use this object to cycle through the array
            let obj = {curImg: 0};

            // create tween
            let tween = TweenMax.to(obj, 0.5,
                {
                    curImg: images.length - 1,	            // animate property curImg to number of images
                    roundProps: "curImg",				    // only integers so it can be used as an array index
                    immediateRender: true,			        // load first image automatically
                    ease: TweenMax.Linear.ease,			// show every image the same amount of time
                    onUpdate: () => updatePhonesAnim(obj.curImg, tween, this.wrapper, this.sprites)
                }
            );

            // build scene
            this.scene.push(
                new ScrollMagic.Scene({
                    offset: 0,
                    duration: Utils.calcDuration(1) - .1
                }).setTween(tween).addTo(controller)
            );

            this.pixelImgTag = (k) => <img
                src={pixel}
                onLoad={k === 0 ? () => updatePhonesAnim(0, tween, this.wrapper, this.sprites) : ()=>{}}
                alt=""
                style={{WebkitMaskImage: `url(${imagesMasks[k]})`, WebkitMaskSize: 'contain'}}/>

        }


        asciiScrollAnimation(controller, this.scene, sectionsTiming);


    }

    componentWillUnmount() {
        this.scene.forEach(function (el) {
            el.destroy(true);
            el = null;
        });
    }

    textSlides = [
        {className: 'mobile-native-applications', content: FirstSlide},
        {className: 'platforms', content: PlatformsSlide},
        {className: 'schedule', content: ScheduleSlide}
    ];

    imagesSlides = () => {
        // Show only one image on mobile
        let useImages = images;
        if (this.state.isMobile) {
            useImages = [images[0]];
        }
        const firstSlide = {
            className: '',
            content:
                <div style={{overflow: 'hidden', maxHeight: '45.5em'}} ref={wrapper => this.wrapper = wrapper}>
                    <div className={'sprites'} ref={sprites => this.sprites = sprites}
                         style={{display: 'flex', flexDirection: 'column'}}>
                        {useImages.map((v, k) => (
                            <picture key={k}>
                                <source srcSet={v} media={k===0 ? "" : "(min-width: 768px)"}
                                />
                                {this.pixelImgTag(k)}
                            </picture>
                        ))}
                    </div>
                </div>
        };
        const mobileSlide = {
            className: '',
            content:
                <div>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        {[images[73]].map((v, k) => (
                            <img key={k} src={v}
                                 style={{WebkitMaskImage: `url(${imagesMasks[73]})`, WebkitMaskSize: 'contain'}}
                                 alt=""/>
                        ))}
                    </div>
                </div>
        };
        const lastSlide = {
            className: 'mobile-images-to-ascii',
            content:
                <ImageAscii
                    image={this.props.data.asciiOriginalImage}
                    mask={mask}
                    asciiImage={this.props.data.asciiImage}
                />
        };

        const slides = [];
        slides.push(firstSlide);
        if (this.state.isMobile) {
            slides.push(mobileSlide);
        }
        slides.push(lastSlide);

        return slides;

    };

    render() {
        const footerOffset = Utils.calcFooterOffset(sectionsTiming);
        return (
            <Layout className="page-mobile-native-application"
                    isIncludeFooter={true}
                    footerOffset={footerOffset}
                    yoastData={this.props.data.wordpressPage.yoast || null}
                    seoTitle={this.props.data.wordpressPage.acf.seoTitle}
                    pageTitle={this.props.data.wordpressPage.title}
            >
                <Subgrid pageTitle={'Mobile Native Applications'} textSlides={this.textSlides}
                         imagesSlides={this.imagesSlides()} sectionsTiming={sectionsTiming}
                         footerOffset={footerOffset}/>
            </Layout>
        )
    }
}

export default MobileNativeApplicationsPage

export const query = graphql`
    query {
        wordpressPage(template: {eq: "mobile-native-apps.php"}){
            title
            path
            ...YoastData
            acf {
                seoTitle: seo_title
            }
        }
        asciiOriginalImage: file(relativePath: { eq: "mobile-native-applications/iphones.png"}) {
            childImageSharp {
                fluid(maxWidth: 1400){
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        } 
        asciiImage: file(relativePath: { eq: "mobile-native-applications/ascii.png"}) {
            childImageSharp {
                fluid(maxWidth: 1400){
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        } 

    }
`;