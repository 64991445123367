import React from "react";
import CodicateImage from "../codicate-image";
import iosImg from "../../resources/images/mobile-native-applications/ios-icon.png";
import reactNativeImg from "../../resources/images/mobile-native-applications/react-native-icon.png";
import androidImg from "../../resources/images/mobile-native-applications/android-icon.png";

const PlatformsSlide = (
    <>
        <span className="small-title">MOBILE NATIVE APPLICATIONS</span>
        <span className={'titles'}>
                        <span className="largeText">ONE CODEBASE - </span>
                        <strong>BOTH PLATFORMS</strong>
                    </span>
        <div className="icons-container">
            <span className="ios"><CodicateImage alt="ios icon" img={iosImg}/></span>
            <span className="react-native"><CodicateImage alt="react icon" img={reactNativeImg}/></span>
            <span className="android"><CodicateImage alt="android icon" img={androidImg}/></span>
        </div>
    </>
);

export default PlatformsSlide;