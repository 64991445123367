import React from "react";

const ScheduleSlide = (
    <>
        <span className="small-title">MOBILE NATIVE APPLICATIONS</span>
        <span className="titles">
            <span className="largeText">DEVELOPMENT - </span>
            <strong>ON YOUR SCHEDULE</strong>
        </span>
        <span className="subTitle">We’ve made tight timelines and complex requirements work. Every time.</span>
    </>
);

export default ScheduleSlide;