import React from 'react';
import Utils from "../../utils/utils";
import ScrollMagic from 'scrollmagic';
import './style.scss';
import CodicateImage from "../codicate-image";

export const asciiScrollAnimation = (controller, scene, sectionsTiming) => {
    // image to ascii animation
    const lastIndex = sectionsTiming.length - 1;
    const offsetDelay = Utils.calcDuration(0.2);
    const animationOffset = Utils.calcSectionOffset(sectionsTiming, lastIndex) + offsetDelay;
    //we want the animation to stop as soon as the footer going up animation starts
    const animationDuration = sectionsTiming[lastIndex].duration - Utils.calcDuration( 0.5) - offsetDelay;

    scene.push(new ScrollMagic.Scene({offset: animationOffset, duration: animationDuration})
    // .addIndicators({name: 'image to ascii'}) // add indicators
        .on("progress", function (event) {
            document.querySelector('.asciiImageContainer > :nth-child(1)').style.webkitClipPath = 'inset('+ (100 * event.progress) + '% 0 0)';
            document.querySelector('.asciiImageContainer > :nth-child(2)').style.webkitClipPath = 'inset(0 0 '+ (100 * (1 - event.progress)) + '%)';
        })
        .addTo(controller));
}

const ImageAscii = ({image,mask,asciiImage}) => {

    return (
        <div className='asciiImageContainer' >
            <CodicateImage img={image} alt=""/>
            <CodicateImage img={asciiImage} style={{WebkitMaskImage: `url(${mask})`}} alt=""/>
        </div>
    )
};

export default ImageAscii